import React from 'react';

// styling
import './WelcomeSection.scss';
import {Container, Typography, Grid} from '@mui/material';

// images
import FirstWelcome from '../../../assets/PNGs/welcome-1.jpg';
import SecondWelcome from '../../../assets/PNGs/welcome-2.jpg';
import ThirdWelcome from '../../../assets/PNGs/welcome-3.jpg';
import Leaf from '../../../assets/PNGs/img-leaf.png';

// component
import FloraTitle from '../../../components/floraTitle/FloraTitle';

const WelcomeSection = ({bgColor = '#fcf5f6'}) => {
  const bgStyle = {
    backgroundColor: bgColor
  }

  return (
    <section className="welcome-section" style={bgStyle}>
      <Container maxWidth="xl">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <FloraTitle
            title={'I am Tunder'}
            subtitle={'and this is my internet corner'}
          />
          <Typography
            variant="subtitle1"
            align="center"
            maxWidth="800px"
            pt={7}
          >
           Endlessly inspired by nature, history and minimalism and obsessed with transforming preloved goods into unique compositions for the home [and not only]. Can’t fit in a box, and constantly experimenting with techniques, the listed categories are always changing, so not expecting anything but being open to discovering is the best approach one can have in order to know me. Enjoy your stay
          </Typography>
        </div>
        {/* <div className="welcome-grid">
          <Grid container spacing={4}>
            <Grid item md={4} sm={12}>
              <div className="d-flex flex-column justify-content-center">
                <div className="welcome-image d-flex justify-content-center align-items-center">
                  <img src={FirstWelcome} alt="welcome-img" />
                </div>
                <Typography variant="h5" align="center" fontWeight="600">
                  Who we are?
                </Typography>
                <div className="d-flex justify-content-center">
                  <img src={Leaf} alt="leaf" />
                </div>
                <Typography
                  variant="subtitle1"
                  align="center"
                  maxWidth="800px"
                  pt={4}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
              </div>
            </Grid>
            <Grid item md={4} sm={12}>
              <div className="d-flex flex-column justify-content-center">
                <div className="welcome-image">
                  <img src={SecondWelcome} alt="welcome-img" />
                </div>
                <Typography variant="h5" align="center" fontWeight="600">
                  How we work?
                </Typography>
                <div className="d-flex justify-content-center">
                  <img src={Leaf} alt="leaf" />
                </div>
                <Typography
                  variant="subtitle1"
                  align="center"
                  maxWidth="800px"
                  pt={4}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
              </div>
            </Grid>
            <Grid item md={4} sm={12}>
              <div className="d-flex flex-column justify-content-center">
                <div className="welcome-image">
                  <img src={ThirdWelcome} alt="welcome-img" />
                </div>
                <Typography variant="h5" align="center" fontWeight="600">
                  Our awesome products
                </Typography>
                <div className="d-flex justify-content-center">
                  <img src={Leaf} alt="leaf" />
                </div>
                <Typography
                  variant="subtitle1"
                  align="center"
                  maxWidth="800px"
                  pt={4}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div> */}
      </Container>
    </section>
  );
};

export default WelcomeSection;
