import './App.scss';
import {Switch, Route} from 'react-router-dom';
import {useMediaQuery} from '@mui/material';
import React, {Suspense, lazy, useEffect} from 'react';
import CircleLoader from 'react-spinners/CircleLoader';

//Google Analytics
import ReactGA from 'react-ga';

// providers
import ProductsProvider from './contexts/ProductsProvider';
import CategoriesProvider from './contexts/CategoriesProvider';

// components
import Header from './components/header/Header';
import Navigation from './components/navigation/Navigation';
import Footer from './components/footer/Footer';
import GoToTop from './components/goToTop/GoToTop';

// pages without lazy-loading
import Page404 from './pages/page404/Page404';
import SignIn from './pages/onboarding/signIn/SignIn';
import ForgotPassword from './pages/onboarding/forgotPassword/ForgotPassword';
import SignUp from './pages/onboarding/signUp/SignUp';
import AboutUs from './pages/aboutUs/AboutUs';
import ContactUs from './pages/contactUs/ContactUs';
import ProductPage from './pages/shop/ProductPage/ProductPage';


//Legal Pages
import TermsAndConditions from './pages/legal/termsAndConditions';
import RefundPolicy from './pages/legal/refundPolicy';
import PrivacyPolicy from './pages/legal/privacyPolicy';
import Profile from './pages/profile/Profile';
import Postpayment from './pages/post-payment/PostPayment';
import ResetPassword from './pages/onboarding/resetPassword/ResetPassword';


const TRACKING_ID = "UA-226959806-1"; // OUR_TRACKING_ID 
ReactGA.initialize(TRACKING_ID);


// pages with lazy-loading
const Homepage = lazy (() => import ('./pages/homepage/Homepage'));
const Shop = lazy(() => import('./pages/shop/Shop'));
const Cart = lazy(() => import('./pages/cart/Cart'));
const Favourites = lazy(() => import('./pages/favourites/Favourites'));
const Checkout = lazy(() => import('./pages/checkout/Checkout'));

const App = () => {
  const matches = useMediaQuery ('(min-width:992px)');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ProductsProvider>
      <CategoriesProvider>
        <main className="app">
          {matches
            ? <React.Fragment>
                <Header matches={matches} />
                <Navigation />
              </React.Fragment>
            : <Header matches={matches} />}
          <Suspense
            fallback={
              <div className="loader">
                <CircleLoader size={120} color={'#af6464'} />
              </div>
            }
          >
            <GoToTop />
            <Switch>
              <Route path="/" exact component={Homepage} />
              <Route path="/sign-in" exact component={SignIn} />
              <Route path="/forgot-password" exact component={ForgotPassword} />
              <Route path="/reset-password/:id" exact component={ResetPassword} />

              <Route path="/sign-up" exact component={SignUp} />
              {/* <Route path="/about-us" exact component={AboutUs} /> */}
              <Route path="/contact-us" exact component={ContactUs} />
              <Route path="/shop" exact component={Shop} />
              <Route path="/shop/:categoryTitle/:categoryId" exact component={Shop} />
              <Route path="/shop/:productTitle" exact component={ProductPage} />
              <Route path="/cart" exact component={Cart} />
              <Route path="/favourites" exact component={Favourites} />
              <Route path="/checkout" exact component={Checkout} />
              <Route path="/termsAndConditions" exact component={TermsAndConditions} />
              <Route path="/privacyPolicy" exact component={PrivacyPolicy} />
              <Route path="/refundPolicy" exact component={RefundPolicy} />
              <Route path="/profile" exact component={Profile} />
              <Route path="/payment-success-paypal" exact component={Postpayment} />

              <Route path="*" exact component={Page404} />
            </Switch>
          </Suspense>
          <Footer />
        </main>
      </CategoriesProvider>
    </ProductsProvider>
  );
};

export default App;
