import React from 'react';

// styling
import './Breadcrumbs.scss';

// utils
import {Link, Typography, Breadcrumbs} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import {useLocation, useHistory} from 'react-router';

const breadcrumbNameMap = {
  '/': 'Myha',
  '/sign-in': 'Sign In',
  '/sign-up': 'Sign Up',
  '/forgot-password': 'Forgot Password',
  '/about-us': 'About us',
  '/contact-us': 'Contact us',
  '/shop': 'Shop',
  '/cart': 'Cart',
  '/favourites': 'My Favourites',
  '/checkout': 'Checkout'
};

const LinkRouter = props => <Link {...props} component={RouterLink} />;

export default function RouterBreadcrumbs({marginBottom = '5rem'}) {
  const location = useLocation ();
  const history = useHistory ();

  const marginStyle = {
    marginBottom: marginBottom,
  };

  const pathnames = location.pathname.split ('/').filter (x => isNaN(parseInt(x)) && x.length>0);

  return (
    <div className="breadcrumbs" style={marginStyle}>
      <Typography variant="h4" pb={2} fontWeight="bold">
        {breadcrumbNameMap[`/${pathnames[pathnames.length - 1]}`]}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <LinkRouter
          underline="hover"
          color="inherit"
          variant="h6"
          sx={{fontWeight: 'bold'}}
          to={'/'}
          onClick={() => history.push ('/')}
        >
          Design by Tunder
        </LinkRouter>
        {pathnames.map ((_, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice (0, index + 1).join ('/')}`;

          return last
            ? <Typography
                variant="h6"
                fontWeight="bold"
                color="secondary"
                key={to}
              >
                {breadcrumbNameMap[to]
                  ? breadcrumbNameMap[to]
                  : to
                      .split ('/')[to.split ('/').length - 1]
                      .split ('_')
                      .map (
                        str => str.charAt (0).toUpperCase () + str.slice (1)
                      )
                      .join (' ')}
              </Typography>
            : <LinkRouter
                underline="hover"
                color="inherit"
                variant="h6"
                sx={{fontWeight: 'bold'}}
                to={to}
                onClick={() => history.push (to)}
                key={to}
              >
                {breadcrumbNameMap[to]}
              </LinkRouter>;
        })}
      </Breadcrumbs>
    </div>
  );
}
