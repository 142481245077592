import React, {useState, useContext, useEffect} from 'react';
// utils
import axios from 'axios';
import {logout} from '../utils/isLogin';

const UserContext = React.createContext ();
const SetUserContext = React.createContext ();

export const useUserContext = () => useContext (UserContext);
export const useSetUserContext = () => useContext (SetUserContext);

const UserProvider = ({children}) => {
  const [userData, setUserData] = useState (null);

  useEffect (() => {
    // get user data
    const fetchUserData = async () => {
      if (!localStorage.getItem ('jwt')) return;
      try {
        let userReq = await axios.get (
          process.env.REACT_APP_MYHA_URL + '/users/me',
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
            },
          }
        );
        if (userReq.status === 200) {
          setUserData (userReq.data);
        }
      } catch (e) {
        console.log (e);
        if (e.response) {
          switch (e.response.status) {
            default:
              logout ();
              window.location.reload(false);
          }
        }
      }
    };

    fetchUserData ();
  }, []);

  return (
    <UserContext.Provider value={userData}>
      <SetUserContext.Provider value={setUserData}>
        {children}
      </SetUserContext.Provider>
    </UserContext.Provider>
  );
};

export default UserProvider;
