import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

// component
import App from './App';

// styling
import './index.scss';
import {createTheme, ThemeProvider} from '@mui/material/styles';

// utils
import {BrowserRouter} from 'react-router-dom';

// providers
import UserProvider from './contexts/UserProvider';
import CartProvider from './contexts/CartProvider';
import FavouritesProvider from './contexts/FavouritesProvider';

const theme = createTheme ({
  palette: {
    primary: {
      main: '#696969',
      light: '#ababab',
    },
    secondary: {
      main: '#af6464',
    },
  },
  typography: {
    fontFamily: ['"Scheherazade New"', 'serif'].join (','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
});

ReactDOM.render (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <UserProvider>
        <CartProvider>
          <FavouritesProvider>
            <App />
          </FavouritesProvider>
        </CartProvider>
      </UserProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById ('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals ();
