import React from 'react';

// sections
import ContactForm from './contactForm/ContactForm';

// components
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Subscribe from '../../components/subscribe/Subscribe';

const ContactUs = () => {
  return (
    <div>
      <Breadcrumbs marginBottom="0" />
      <ContactForm />
      <Subscribe />
    </div>
  );
};

export default ContactUs;
