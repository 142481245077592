import React, {useState, useEffect} from 'react';

// @mui
import {
  Backdrop,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

// utils
import axios from 'axios';
import {formatDate} from '../../utils/formatDate';
import {useHistory} from 'react-router-dom';

// styling
import './BackdropComp.scss';

const BackdropComp = ({openSearchBackdrop, handleCloseSearchBackdrop}) => {
  const [searchInput, setSearchInput] = useState ('');
  const [products, setProducts] = useState ([]);
  const [loading, setLoading] = useState (false);
  const history = useHistory();

  const handleSearchInput = event => {
    setSearchInput (event.target.value);
  };

  const handleShowProduct = (title) => {
    let link = title.toLowerCase().split(" ");
    link = link.join("_");

    handleCloseSearchBackdrop(setSearchInput, setLoading);
    history.push(`/shop/${link}`)
  }

  useEffect (
    () => {
      setLoading (true);
      if (searchInput.length < 3) return setLoading (false);
      const searchProducts = async () => {
        try {
          let searchProductsReq = await axios.get (
            process.env.REACT_APP_MYHA_URL + '/searchProducts',
            {
              params: {
                filter: searchInput,
              },
            }
          );
          if (searchProductsReq.status === 200) {
            setProducts (searchProductsReq.data);
          }
        } catch (e) {
          console.log (e);
        } finally {
          setLoading (false);
        }
      };

      const delayDebounceFn = setTimeout (() => {
        searchProducts ();
      }, 2000);

      return () => clearTimeout (delayDebounceFn);
    },
    [searchInput]
  );

  let toRenderResult;

  if (!loading) {
    if (searchInput.length > 0 && searchInput.length < 3) {
      toRenderResult = (
        <Typography p={2}>Please type at least 3 character to search...</Typography>
      );
    } else if (products.length > 0) {
      toRenderResult = products.map (product => (
        <div key={product.id} className="product-row d-flex" onClick={() => handleShowProduct(product.title)}>
          <div className="product-content">
            <div
              className="product-image"
              style={{
                backgroundImage: `url(${product.imageUrl})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: '60px',
                height: '60px',
              }}
            />
            <div className="product-details">
              <Typography variant="subtitle1">{product.title}</Typography>
              <Typography variant="subtitle2">
                {formatDate (product.created_at)}
              </Typography>
            </div>
          </div>
        </div>
      ));
    } else {
      toRenderResult = (
        <Typography p={2}>No products were found matching your selection.</Typography>
      );
    }
  }

  return (
    <Backdrop
      sx={{
        color: '#ffffff',
        zIndex: theme => theme.zIndex.drawer + 9,
        background: 'rgba(0, 0, 0, 0.85)',
      }}
      open={openSearchBackdrop}
      className="backdrop"
    >
      <CloseIcon className="close-icon" onClick={() => handleCloseSearchBackdrop(setSearchInput, setLoading)} />
      <div className="d-flex flex-column">
        <TextField
          onChange={handleSearchInput}
          value={searchInput}
          name="search"
          size="medium"
          className="backdrop-input"
          id="searchBackdrop"
          type="text"
          variant="standard"
          color="primary"
          autoComplete="off"
          InputLabelProps={{
            style: {color: '#ffffff', borderColor: '#ffffff'},
          }}
          label="Search for..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="search-icon" edge="end">
                  {searchInput.length !== 0 && loading
                    ? <CircularProgress size={20} color="secondary" />
                    : <SearchIcon color="primary" />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {searchInput.length !== 0 &&
          !loading &&
          <div className="search-result">
            {toRenderResult}
          </div>}
      </div>
    </Backdrop>
  );
};

export default BackdropComp;
