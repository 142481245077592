import React, {useState, useContext, useEffect} from 'react';
// utils
import axios from 'axios';
import cogoToast from 'cogo-toast';

const CartContext = React.createContext ();

export const useCartContext = () => useContext (CartContext);

const CartProvider = ({children}) => {
  const [cartProducts, setCartProducts] = useState ([]);
  const [loadingCart, setLoadingCart] = useState (true);
  const [activeCouponDiscount, setActiveCouponDiscount] = useState ('');


  const syncCartWithServer = async () => {

    const lsCart = JSON.parse(localStorage.getItem('cart'));

    const addToCartReq = await axios.post (
      process.env.REACT_APP_MYHA_URL + '/createCart',
      lsCart,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
        },
      }
    );

    if (addToCartReq.status === 200) {
      return cogoToast.success ('Products added succesfully to cart');
    }


  }
  const getCartProducts = async () => {
    // if user is logged in get cart from db
    // else from local storage

    const lsCart = JSON.parse(localStorage.getItem('cart'));

      if (lsCart) {
        setCartProducts (lsCart);
      } else {
        setCartProducts ([]);
      }

      
    setLoadingCart (true);
    if (localStorage.getItem ('jwt')) {
      try {
        const getCartProductsReq = await axios.get (
          process.env.REACT_APP_MYHA_URL + '/getCartProducts',
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
            },
          }
        );

        if (getCartProductsReq.status === 200) {
          setCartProducts (getCartProductsReq.data);
        }
      } catch (e) {
        console.log (e);
        if (e.response) {
          switch (e.response.status) {
            default:
              return cogoToast.error ('An unexpected error occurred');
          }
        }
      } finally {
        setLoadingCart (false);
      }
    } 
    setLoadingCart (false);

    
  };

  const addToCart = async (product, quantity, displayMessage) => {
    // if user is logged in, add to db cart, else add in localStorage

    const lsCart = JSON.parse(localStorage.getItem('cart'));

    // if cart is null add the product
    // else verify if the product already exists
    // if it does increase quantity
    // else add product with qunatity
    let cart;
    if (lsCart === null) {
      cart = [
        {
          ...product,
          quantity,
        }
      ];
    } else {
      cart = [...lsCart];
      let productInCart = false;

      const updatedCart = cart.map (prod => {
        if (prod.id === product.id) {
          productInCart = true;
          return {
            ...prod,
            quantity: prod.quantity + quantity,
          };
        } else {
          return prod;
        }
      });

      if (!productInCart) {
        cart = [
          ...cart,
          {
            ...product,
            quantity,
          },
        ];
      } else {
        cart = updatedCart;
      }
    }

    localStorage.setItem ('cart', JSON.stringify(cart));
    setCartProducts(cart)

    if (localStorage.getItem ('jwt')) {
      try {
        const obj = {
          product: product,
          productQuantity: quantity,
        };

        const addToCartReq = await axios.post (
          process.env.REACT_APP_MYHA_URL + '/addToCart',
          obj,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
            },
          }
        );

        if (addToCartReq.status === 200 && displayMessage) {
          return cogoToast.success ('Product added succesfully to cart');
        }
      } catch (e) {
        console.log (e);
        if (e.response) {
          switch (e.response.status) {
            default:
              return cogoToast.error ('An unexpected error occurred');
          }
        }
      }
    } 
     
      if (displayMessage) {
        return cogoToast.success ('Product added succesfully to cart');
      }
    
  };

  const removeFromCart = async productId => {
    // if user is logged in remove from db
    // else remove from local storage


    const lsCart = JSON.parse(localStorage.getItem('cart'));

      const updatedCart = lsCart.filter (product => {
        return product.id !== productId;
      });

      localStorage.setItem ('cart', JSON.stringify(updatedCart));
      setCartProducts(updatedCart)
      cogoToast.success ('Product removed succesfully from cart');


    if (localStorage.getItem ('jwt')) {
      try {
        const obj = {
          productId: productId,
        };

        const removeFromCartReq = await axios.post (
          process.env.REACT_APP_MYHA_URL + '/removeFromCart',
          obj,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
            },
          }
        );

        if (removeFromCartReq.status === 200) {
          // return cogoToast.success ('Product removed succesfully from cart');
        }
      } catch (e) {
        console.log (e);
        if (e.response) {
          switch (e.response.status) {
            default:
              return cogoToast.error ('An unexpected error occurred');
          }
        }
      }
    }
      

  };

  const substractProductQuantity = async productId => {
    // if user is logged in substract from db
    // else substract from local storage
    if (localStorage.getItem ('jwt')) {
      try {
        const obj = {
          productId: productId,
        };

        await axios.post (
          process.env.REACT_APP_MYHA_URL + '/substractProductQuantity',
          obj,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
            },
          }
        );
      } catch (e) {
        console.log (e);
        if (e.response) {
          switch (e.response.status) {
            default:
              return cogoToast.error ('An unexpected error occurred');
          }
        }
      }
    } else {
      const lsCart = JSON.parse(localStorage.getItem('cart'));

      const product = lsCart.find (product => product.id === productId);
      const productQuantity = product.quantity;

      let updatedCart;
      if (productQuantity > 1) {
        updatedCart = lsCart.map (product => {
          if (product.id === productId) {
            return {
              ...product,
              quantity: product.quantity - 1,
            };
          } else {
            return product;
          }
        });
      } else {
        updatedCart = lsCart.filter (product => {
          return product.id !== productId;
        });
      }

      localStorage.setItem ('cart', JSON.stringify(updatedCart));
    }
  };

  const validateCoupon = async (coupon) => {
    try {
      let couponReq = await axios.post (
        process.env.REACT_APP_MYHA_URL + '/validateCoupon',
        {couponCode: coupon}
      );
      if (couponReq.status === 200) {
        if (couponReq.data.isCouponValidated) {
          cogoToast.success ('Coupon applied succesfully.');
          setActiveCouponDiscount (couponReq.data);
        } else {
          cogoToast.error ('Invalid coupon.');
        }
      }
    } catch (e) {
      console.log (e);
      if (e.response) {
        switch (e.response.status) {
          default:
            return cogoToast.error ('An unexpected error occurred');
        }
      }
    }
  };

  useEffect (() => {
    getCartProducts ();
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartProducts,
        getCartProducts,
        loadingCart,
        addToCart,
        removeFromCart,
        substractProductQuantity,
        validateCoupon,
        activeCouponDiscount
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
