import React from 'react'
import './Profile.scss'

import Navbar from './Navbar/Navbar'
import Orders from './Orders/Orders'
import NavbarElement from './NavbarElement/NavbarElement'
import cogoToast from 'cogo-toast';
import axios from 'axios'
import { Button } from '@mui/material'


class Postpayment extends React.Component{

    state = {
        paymentStatus : "unpaid"

    }

    async componentDidMount() {
        
        try {      

            let queries = this.props.location.search;
            console.log(queries)
            let paymentID = queries.split("paymentId=")[1].split("&")[0]
            let payerID = queries.split("PayerID=")[1]
            console.log(payerID)
            const data = await axios.get (
                process.env.REACT_APP_MYHA_URL + '/orders/confirmPaypal/' + paymentID + "/" + payerID,
                {
                  headers: {
                    Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
                  },
                }
              );

            if (data.status === 200) {
                console.log(data.data)

                if ( data.data.state === "approved" )
                {
                    console.log("ok")
                    this.setState({
                        paymentStatus : "paid"
                    })
                }
                  
            //    this.setState({
            //        generateInvoice : "data:application/pdf;base64," + invoice.data.pdf
            //    })
                

            };
          } catch(e) {
            console.log(e);
            if (e.response.status) {
              switch (e.response.status) {
                default:
                  return cogoToast.error("An unexpected error occurred");
              }
            }
          }

    }

  

    render(){
        
        let text= <p> Processing Payment </p>
        if ( this.state.paymentStatus === "paid"){
            text= <div>
                <p> Payment Successful! </p>
                <Button onClick = {() => this.props.history.push("/profile")}> Go to your profile </Button>
            </div>

        }

        return (

            <div className = "profile">
                {text}
            </div>
    
        )
    }

}


export default Postpayment;