import React from 'react'
import NavbarElement from '../NavbarElement/NavbarElement'

class Navbar extends React.Component{

    
    render(){

        const navbarElements = this.props.elements.map( (element) => {

            let style =  {backgroundColor: "light-gray"}

            if ( this.props.active === element )
                style = {backgroundColor: "#af6464"}

            return <NavbarElement onClick = {() => this.props.handleClick(element)} details = {{element : element, active : this.props.active, style : style}} />
        })
        return(
            <div>
                {navbarElements}
            </div>
        )
    }
}

export default Navbar;