import React, {useState} from 'react';

// utils
import {useHistory} from 'react-router-dom';
import {signUp} from '../../../utils/routes';

// styling
import './ForgotPassword.scss';
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  Link,
  InputLabel,
} from '@mui/material';
import cogoToast from 'cogo-toast';
import axios from 'axios'

// components
import Subscribe from '../../../components/subscribe/Subscribe';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';




const ForgotPassword = () => {
  const [forgotPasswordObj, setForgotPasswordObj] = useState ({
    email: '',
  });

  const history = useHistory ();

  const handleInputChange = event => {
    setForgotPasswordObj ({
      ...forgotPasswordObj,
      [event.target.name]: event.target.value,
    });
  };

   // signIn possible just if button is enabled ( form is validated )
 const handleReset = async () => {
  try {
    const resetPassword = await axios.post (
      process.env.REACT_APP_MYHA_URL + '/auth/forgot-password',
      {
        email: forgotPasswordObj.email,
      }
    );

    console.log(resetPassword);
    if (resetPassword.status === 200) {
      // auth
      cogoToast.success ('An email has been sent to you');

      // history.push (homePage);
    }
  } catch (e) {
    console.log (e);
    if (e.response.status) {
      switch (e.response.status) {
        case 400:
          return cogoToast.error ('User not found');
        default:
          return cogoToast.error ('An unexpected error occurred');
      }
    }
  }
};

  return (
    <div className="forgot-password">
      <Breadcrumbs />
      <Container maxWidth="xl" className="forgot-password-container">
        <Typography variant="h4" fontWeight="bold">FORGOT PASSWORD</Typography>
        <Typography pb={3} variant="body1">
          Lost your password? Please enter your email address. You will receive a link to create a new password via email.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 3,
            border: `1px solid #ababab`,
          }}
          component="form"
        >
          <InputLabel sx={{fontWeight: 'bold'}} htmlFor="my-em">
            Email address <sup style={{color: 'red'}}>&#42;</sup>
          </InputLabel>
          <FormControl>
            <TextField
              onChange={handleInputChange}
              name="email"
              autoComplete="on"
              color="secondary"
              size="small"
              type="email"
              id="my-em3"
            />
          </FormControl>
          <div className="d-flex">
            <Button
              variant="contained"
              color="secondary"
              sx={{width: '200px', mt: 3}}
              onClick={handleReset}
            >
              Reset Password
            </Button>

          </div>
          <Link
            variant="body1"
            onClick={() => history.push (signUp)}
            mt={3}
            sx={{cursor: 'pointer'}}
          >
            Don't you have an account yet?
          </Link>
        </Box>
      </Container>
      <Subscribe />
    </div>
  );
};

export default ForgotPassword;
