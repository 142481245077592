import React from 'react';

// styling
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';

// components
import ProductItem from '../../../pages/shop/ProductPage/productItem/ProductItem';

const ProductModal = ({open, onCloseModal, title}) => {
  return (
    <Modal style={{zIndex: 9999}} open={open} onClose={onCloseModal} center>
      <ProductItem modalView={true} param={title} />
    </Modal>
  );
};

export default ProductModal;
