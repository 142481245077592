import React, {useState, useContext, useEffect} from 'react';

// utils
import axios from 'axios';

const CategoriesContext = React.createContext ();
const LoadingCategoriesContext = React.createContext ();

export const useCategoriesContext = () => useContext (CategoriesContext);
export const useLoadingCategoriesContext = () => useContext (LoadingCategoriesContext);

const CategoriesProvider = ({children}) => {
  const [categories, setCategories] = useState ([]);
  const [loading, setLoading] = useState (true);

  useEffect (() => {
    const fetchCategories = async () => {
      setLoading (true);
      try {
        let categoriesReq = await axios.get (
          process.env.REACT_APP_MYHA_URL + '/getCategories'
        );
        if (categoriesReq.status === 200) {
          setCategories (categoriesReq.data);
        }
      } catch (e) {
        console.log (e);
      } finally {
        setLoading (false);
      }
    };

    fetchCategories ();
  }, []);

  return (
    <CategoriesContext.Provider value={categories}>
      <LoadingCategoriesContext.Provider value={loading}>
        {children}
      </LoadingCategoriesContext.Provider>
    </CategoriesContext.Provider>
  );
};

export default CategoriesProvider;
