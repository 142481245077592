import React from 'react';

// stylng
import './SocialBadge.scss';
import {
    FaFacebookF,
    FaInstagram,
    FaYoutube,
    FaPinterest
  } from 'react-icons/fa';

const SocialBadge = ({socialIcon, link, dimension = '50px'}) => {
    const dimensionStyle = {
        width: dimension,
        height: dimension
    }

    const renderSocialIcon = (socialIcon, link) => {
        if (socialIcon === "facebook") {
            return <a target="_blank" href = {link}><FaFacebookF alt="social-img" className="social-img" /></a>;
        } else if (socialIcon === "pinterest") {
            return <a target="_blank" href = {link}><FaPinterest alt="social-img" className="social-img" /></a>;
        } else if (socialIcon === "instagram") {
            return <a target="_blank" href = {link}><FaInstagram alt="social-img" className="social-img" /></a>;
        } else if (socialIcon === "youtube") {
            return <a target="_blank" href = {link}><FaYoutube alt="social-img" className="social-img" /></a>;
        }
    }

    return (        
        <div className="social-badge" style={dimensionStyle}>
            {renderSocialIcon(socialIcon, link)}
        </div>
    )
}

export default SocialBadge;