import React from 'react';

// stylng
import './MiniBadge.scss';

const MiniBadge = ({children}) => {
    return (
        <div className="mini-badge">
            {children}
        </div>
    )
}

export default MiniBadge;