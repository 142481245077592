import React from 'react';

// router
import {useHistory} from 'react-router-dom';

// styling
import './Page404.scss';
import {Container, Typography, Button} from '@mui/material';

// components
import Subscribe from '../../components/subscribe/Subscribe';
import { homePage } from '../../utils/routes';

const Page404 = () => {
  const history = useHistory();

  const goToHomepage = () => {
    history.push(homePage);
  }

  return (
    <div className="page-404">
      <Container maxWidth="xl">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Typography variant="h1" color="secondary">404</Typography>
          <Typography variant="h4" align="center">Sorry, looks like this page doesn't exist</Typography>
          <Typography variant="body" pt={2}>You could either go to homepage</Typography>
          <Button onClick={goToHomepage} variant="contained" color="secondary" sx={{mt: 5, mb: 13}}>Back to home</Button>
        </div>
      </Container>
      <Subscribe />
    </div>
  );
};

export default Page404;