import React, {useState} from 'react';

// images
import DtLogo from '../../assets/SVGs/logo.svg';

// styling
import './Header.scss';
import {
  Container,
  Divider,
  Box,
  Typography,
  AppBar,
  useScrollTrigger,
  Fab,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
  FaPinterest,
} from 'react-icons/fa';
import {BsFillPersonFill, BsEmojiHeartEyes} from 'react-icons/bs';
import {
  AiOutlineSearch,
  AiOutlineHeart,
  AiOutlineHome,
  AiOutlineShopping,
} from 'react-icons/ai';
import {GiHamburgerMenu} from 'react-icons/gi';
import {MdPhonelink} from 'react-icons/md';
import {BsCartPlus} from 'react-icons/bs';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';

// components
import MiniBadge from '../badges/miniBadge/MiniBadge';
import ScrollToTop from '../scrollToTop/ScrollToTop';
import BackdropComp from '../backdrop/BackdropComp';

// utils
import {useHistory} from 'react-router-dom';
import {signIn, signUp, homePage, profile, cart} from '../../utils/routes';
import {logout} from '../../utils/isLogin';

// providers
import {useSetUserContext, useUserContext} from '../../contexts/UserProvider';
import {useCartContext} from '../../contexts/CartProvider';
import {useFavouritesContext} from '../../contexts/FavouritesProvider';

const Header = ({matches}) => {
  // drawer state
  const [openDrawer, setOpenDrawer] = useState(false);
  // backdrop state
  const [openSearchBackdrop, setOpenSearchBackdrop] = useState (false);

  // context
  const userData = useUserContext ();
  const setUserData = useSetUserContext();
  const {cartProducts, getCartProducts} = useCartContext();
  const {getFavourites} = useFavouritesContext();
  const history = useHistory ();
  const trigger = useScrollTrigger ({
    disableHysteresis: true,
    threshold: 68,
  });

  const handleLogout = () => {
    logout();
    setUserData(null);
    getCartProducts();
    getFavourites();
  }

  const goToCart = () => {
    history.push (cart);
  };

  // backdrop functions
  const handleToggleSearchBackdrop = () => {
    setOpenSearchBackdrop (true);
    handleCloseDrawer();
  };

  const handleCloseSearchBackdrop = (setSearchInput, setLoading) => {
    setOpenSearchBackdrop (false);
    setSearchInput ('');
    setLoading (false);
  };

  // drawer handlers
  const handleOpenDrawer = () => setOpenDrawer(true);
  const handleCloseDrawer = () => setOpenDrawer(false);
  
  return (
    <nav className="header">
      <Container maxWidth="xl">
        <Box
          py={1}
          display="flex"
          className="custom-breakpoint"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" className="icons-container">
            <FaFacebookF
              onClick={() => window.open ('https://www.facebook.com/designedbytunder/', '_blank')}
            />
            <FaPinterest
              onClick={() => window.open ('https://ro.pinterest.com/designbytunder/', '_blank')}
            />
            <FaYoutube
              onClick={() => window.open ('https://www.youtube.com/c/DesignbyTunder', '_blank')}
            />
            <FaInstagram
              onClick={() => window.open ('https://www.instagram.com/designbytunder/', '_blank')}
            />
          </Box>
          <Box display="flex" alignItems="center">
            {!userData
              ? <React.Fragment>
                  <Typography
                    className="header-link"
                    color="primary.light"
                    pr={1}
                    variant="body2"
                    onClick={() => history.push (signIn)}
                  >
                    Sign In
                  </Typography>
                  <Typography color="primary.light" variant="caption">
                    |
                  </Typography>
                  <Typography
                    className="header-link"
                    color="primary.light"
                    pl={1}
                    variant="body2"
                    onClick={() => history.push (signUp)}
                  >
                    Register
                  </Typography>
                </React.Fragment>
              : <React.Fragment>
                  <Typography
                    className="header-link"
                    color="primary.light"
                    variant="body2"
                    onClick={() => history.push (profile)}
                  >
                    Hello, {userData.username}!
                  </Typography>
                  <Typography color="primary.light" variant="caption">
                    |
                  </Typography>
                  <Typography
                    className="header-link"
                    color="primary.light"
                    pl={1}
                    variant="body2"
                    onClick={handleLogout}
                  >
                    Sign Out
                  </Typography>
                </React.Fragment>}
          </Box>
        </Box>
      </Container>
      <Divider />
      {matches
        ? <React.Fragment>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={2}
            >
              <img
                src={DtLogo}
                onClick={() => history.push (homePage)}
                className="logo-img"
                alt="logo"
              />
            </Box>
            <Divider sx={{marginBottom: '1px'}} />
            <Divider />
          </React.Fragment>
        : <React.Fragment>
            <AppBar
              position={trigger ? 'fixed' : 'static'}
              sx={{
                backgroundColor: '#ffffff',
                ...(!trigger && {boxShadow: 'none'}),
              }}
            >
              <Container maxWidth="xl">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py={1}
                >
                  <div className="icon-box" onClick={handleOpenDrawer}>
                    <GiHamburgerMenu />
                  </div>
                  <img
                    onClick={() => history.push (homePage)}
                    src={DtLogo}
                    className="logo-img"
                    alt="logo"
                  />
                  <div className="icon-box">
                    <BsCartPlus onClick={goToCart} />
                    <MiniBadge>
                      {cartProducts.length}
                    </MiniBadge>
                  </div>
                </Box>
              </Container>
            </AppBar>
            <ScrollToTop>
              <Fab
                color="secondary"
                size="small"
                aria-label="scroll back to top"
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </ScrollToTop>
          </React.Fragment>}
          <Drawer
            anchor={'left'}
            open={openDrawer}
            onClose={handleCloseDrawer}
            sx={{
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '240px' },
            }}
          >
            <div className="drawer-header">
              <div></div>
              <Typography variant="subtitle1" fontWeight="600" align="center">MENU</Typography>
              <CloseIcon onClick={handleCloseDrawer} size="small" />
            </div>
            <div className="drawer-actions">
              <div className="d-flex">
                <AiOutlineHeart onClick={() => {history.push('/favourites'); handleCloseDrawer();}} className="margin-icon cursor-pointer" />
                <BsFillPersonFill onClick={() => {history.push('/contact-us'); handleCloseDrawer();}} className="margin-icon cursor-pointer" />
                <AiOutlineSearch onClick={handleToggleSearchBackdrop} className="cursor-pointer" />
              </div>
            </div>
            <List>
              <ListItem button onClick={() => {history.push('/'); handleCloseDrawer();}}>
                <ListItemIcon> <AiOutlineHome /></ListItemIcon>
                <ListItemText primary={'Home'} />
              </ListItem>
              <ListItem button onClick={() => {history.push('/shop'); handleCloseDrawer();}}>
                <ListItemIcon> <AiOutlineShopping /></ListItemIcon>
                <ListItemText primary={'Shop'} />
              </ListItem>
              <ListItem button onClick={() => {history.push('/about-us'); handleCloseDrawer();}}>
                <ListItemIcon> <BsEmojiHeartEyes /></ListItemIcon>
                <ListItemText primary={'About Us'} />
              </ListItem>
              <ListItem button onClick={() => {history.push('/contact-us'); handleCloseDrawer();}}>
                <ListItemIcon> <MdPhonelink /></ListItemIcon>
                <ListItemText primary={'Contact Us'} />
              </ListItem>
            </List>
          </Drawer>
          <BackdropComp
            openSearchBackdrop={openSearchBackdrop}
            handleCloseSearchBackdrop={handleCloseSearchBackdrop}
          />
    </nav>
  );
};

export default Header;
