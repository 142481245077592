import React, {useState, useContext, useEffect} from 'react';

// utils
import axios from 'axios';

const ProductsContext = React.createContext ();
const LoadingProductsContext = React.createContext ();

export const useProductsContext = () => useContext (ProductsContext);
export const useLoadingProductsContext = () => useContext (LoadingProductsContext);

const ProductsProvider = ({children}) => {
  const [products, setProducts] = useState ([]);
  const [loading, setLoading] = useState (true);

  useEffect (() => {
    const fetchProducts = async () => {
      setLoading (true);
      try {
        let productsReq = await axios.get (
          process.env.REACT_APP_MYHA_URL + '/getProducts'
        );
        if (productsReq.status === 200) {
          setProducts (productsReq.data);
        }
      } catch (e) {
        console.log (e);
      } finally {
        setLoading (false);
      }
    };

    fetchProducts ();
  }, []);

  return (
    <ProductsContext.Provider value={products}>
      <LoadingProductsContext.Provider value={loading}>
        {children}
      </LoadingProductsContext.Provider>
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;
