import React, {useState} from 'react';

// styling
import './Navigation.scss';
import {
  AppBar,
  Container,
  useScrollTrigger,
  Box,
  Typography,
  Fab,
  Menu,
} from '@mui/material';
import {
  AiOutlineSearch,
  AiOutlineHeart,
  AiOutlineDown,
  AiOutlineHome,
  AiOutlineShopping,
  AiOutlineProfile,
} from 'react-icons/ai';
import {BsCartPlus, BsPersonFill} from 'react-icons/bs';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// components
import MiniBadge from '../badges/miniBadge/MiniBadge';
import ScrollToTop from '../scrollToTop/ScrollToTop';
import BackdropComp from '../backdrop/BackdropComp';
import PagesMenuContent from './menusContent/PagesMenuContent';
import MyhaMenuContent from './menusContent/MyhaMenuContent';

// utils
import {useHistory} from 'react-router';
import {homePage, shop, cart} from '../../utils/routes';

// providers
import {useCartContext} from '../../contexts/CartProvider';
import {useFavouritesContext} from '../../contexts/FavouritesProvider';
import { GiRamProfile } from 'react-icons/gi';

const Navigation = props => {
  // popovers state
  const [anchorPages, setAnchorPages] = React.useState (null);
  const [anchorMyha, setAnchorMyha] = React.useState (null);

  // backdrop state
  const [openSearchBackdrop, setOpenSearchBackdrop] = useState (false);

  // provider
  const {cartProducts} = useCartContext();
  const {favourites} = useFavouritesContext();

  // library hooks
  const history = useHistory ();
  const trigger = useScrollTrigger ({
    disableHysteresis: true,
    threshold: 126,
  });

  // backdrop functions
  const handleToggleSearchBackdrop = () => {
    setOpenSearchBackdrop (true);
  };

  const handleCloseSearchBackdrop = (setSearchInput, setLoading) => {
    setOpenSearchBackdrop (false);
    setSearchInput ('');
    setLoading (false);
  };

  // menus functions
  // 1. pages menu
  const openPages = Boolean (anchorPages);
  const handleOpenPages = event => {
    setAnchorPages (event.currentTarget);
  };
  const handleClosePages = () => {
    setAnchorPages (null);
  };

  // myha menu
  const openMyha = Boolean (anchorMyha);
  const handleOpenMyha = event => {
    setAnchorMyha (event.currentTarget);
  };
  const handleCloseMyha = () => {
    setAnchorMyha (null);
  };

  // function for routing
  const goToCart = () => {
    history.push (cart);
  };

  return (
    <React.Fragment>
      <AppBar
        sx={{
          backgroundColor: '#ffffff',
          ...(!trigger && {boxShadow: 'none'}),
        }}
        className="app-bar"
        position="sticky"
      >
        <Container maxWidth="xl">
          <Box
            py={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex">
              <Box
                display="flex"
                className="nav-item"
                mr={4}
                alignItems="center"
                onClick={() => history.push (homePage)}
              >
                <Typography color="primary.main" variant="subtitle1">
                  HOME
                </Typography>
                <AiOutlineHome className="color-primary font-size-arrow" />
              </Box>
              {/* <Box
                display="flex"
                className="nav-item"
                mr={4}
                alignItems="center"
              >
                <Typography
                  onMouseEnter={handleOpenMyha}
                  color="primary.main"
                  variant="subtitle1"
                  aria-owns={anchorMyha ? 'simple-menu' : undefined}
                  aria-haspopup="true"
                >
                  FEATURED
                </Typography>
                <AiOutlineDown className="color-primary font-size-arrow" />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorMyha}
                  open={openMyha}
                  onClose={handleCloseMyha}
                  PaperProps={{
                    onMouseLeave: handleCloseMyha,
                    style: {
                      width: '70%',
                      marginTop: 15,
                    },
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    style: {
                      padding: 0,
                    },
                  }}
                >
                  <MyhaMenuContent onClose={handleCloseMyha} />
                </Menu>
              </Box> */}
              <Box
                display="flex"
                className="nav-item"
                mr={4}
                alignItems="center"
              >
                <Typography
                  onClick={() => history.push (shop)}
                  color="primary.main"
                  variant="subtitle1"
                >
                  SHOP
                </Typography>
                <AiOutlineShopping className="color-primary font-size-arrow" />
              </Box>
              {/* <Box display="flex" className="nav-item" alignItems="center">
                <Typography
                  onMouseEnter={handleOpenPages}
                  color="primary.main"
                  variant="subtitle1"
                  aria-owns={anchorPages ? 'simple-menu' : undefined}
                  aria-haspopup="true"
                >
                  PAGES
                </Typography>
                <AiOutlineDown className="color-primary font-size-arrow" />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorPages}
                  open={openPages}
                  onClose={handleClosePages}
                  PaperProps={{
                    onMouseLeave: handleClosePages,
                    style: {
                      width: 250,
                      marginTop: 15,
                    },
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <PagesMenuContent onClose={handleClosePages} />
                </Menu>
              </Box> */}
            </Box>
            <Box display="flex" className="icons-container">
              <Box mr={2} className="icon-box">
                <AiOutlineSearch onClick={handleToggleSearchBackdrop} />
              </Box>
              <Box onClick={goToCart} mr={2} className="icon-box">
                <BsCartPlus />
                <MiniBadge>
                  {cartProducts.length}
                </MiniBadge>
              </Box>
              <Box mr={2} className="icon-box">
                <AiOutlineHeart onClick={() => history.push('/favourites')} />
                <MiniBadge>
                  {favourites.length}
                </MiniBadge>
              </Box>
              <Box className="icon-box">
                <AiOutlineProfile onClick={() => history.push('/profile')} />
                {/* <MiniBadge>
                  {favourites.length}
                </MiniBadge> */}
              </Box>
            </Box>
          </Box>
        </Container>
      </AppBar>
      <BackdropComp
        openSearchBackdrop={openSearchBackdrop}
        handleCloseSearchBackdrop={handleCloseSearchBackdrop}
      />
      <ScrollToTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollToTop>
    </React.Fragment>
  );
};

export default Navigation;
