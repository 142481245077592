import React, {useState} from 'react';

// utils
import {useHistory} from 'react-router-dom';
import {signUp} from '../../../utils/routes';

// styling
import './ResetPassword.scss';
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  Link,
  InputLabel,
} from '@mui/material';
import cogoToast from 'cogo-toast';
import axios from 'axios'

// components
import Subscribe from '../../../components/subscribe/Subscribe';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';




const ResetPassword = () => {
  const [forgotPasswordObj, setForgotPasswordObj] = useState ({
    password1: '',
    password2: '',

  });

  const history = useHistory ();

  const handleInputChange = event => {
    setForgotPasswordObj ({
      ...forgotPasswordObj,
      [event.target.name]: event.target.value,
    });
  };

   // signIn possible just if button is enabled ( form is validated )
 const handleReset = async () => {

  if ( forgotPasswordObj.password1 !== forgotPasswordObj.password2 )
  {
    // console.log(forgotPasswordObj)
    cogoToast.error("Your passwords do not match")
    return;
  }

  let code = window.location.href.split("/")[4];
  // console.log(code)

  try {
    const resetPassword = await axios.post (
      process.env.REACT_APP_MYHA_URL + '/auth/reset-password',
      {
        password: forgotPasswordObj.password1,
        passwordConfirmation: forgotPasswordObj.password2,
        code: code
      }
    );

    if (resetPassword.status === 200) {
      // auth
      cogoToast.success ('You password has been successfully changed');

      history.push ("/sign-in");
    }
  } catch (e) {
    console.log (e);
    if (e.response.status) {
      switch (e.response.status) {
        case 400:
          return cogoToast.error ('User not found');
        default:
          return cogoToast.error ('An unexpected error occurred');
      }
    }
  }
};

  return (
    <div className="forgot-password">
      {/* <Breadcrumbs /> */}
      <Container maxWidth="xl" className="forgot-password-container">
        <Typography variant="h4" fontWeight="bold">RESET YOUR PASSWORD</Typography>
        <Typography pb={3} variant="body1">
          Please enter your new password.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 3,
            border: `1px solid #ababab`,
          }}
          component="form"
        >
          <InputLabel sx={{fontWeight: 'bold'}} htmlFor="my-em">
            New Password <sup style={{color: 'red'}}>&#42;</sup>
          </InputLabel>
          <FormControl>
            <TextField
              onChange={handleInputChange}
              name="password1"
              autoComplete="on"
              color="secondary"
              size="small"
              type="password"
              id="my-em3"
            />
          </FormControl>
          <InputLabel sx={{fontWeight: 'bold'}} htmlFor="my-em">
            Repeat New Password <sup style={{color: 'red'}}>&#42;</sup>
          </InputLabel>
          <FormControl>
            <TextField
              onChange={handleInputChange}
              name="password2"
              autoComplete="on"
              color="secondary"
              size="small"
              type="password"
              id="my-em3"
            />
          </FormControl>
          <div className="d-flex">
            <Button
              variant="contained"
              color="secondary"
              sx={{width: '200px', mt: 3}}
              onClick={handleReset}
            >
              Change your Password
            </Button>

          </div>
          {/* <Link
            variant="body1"
            onClick={() => history.push (signUp)}
            mt={3}
            sx={{cursor: 'pointer'}}
          >
            Already have an ?
          </Link> */}
        </Box>
      </Container>
      <Subscribe />
    </div>
  );
};

export default ResetPassword;
