import React, {useEffect} from 'react';
import ReactGA from 'react-ga';

// components
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import Subscribe from '../../../components/subscribe/Subscribe';
import ProductItem from './productItem/ProductItem';

const ProductPage = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <Breadcrumbs />
      <ProductItem />
      <Subscribe />
    </div>
  );
};

export default ProductPage;
