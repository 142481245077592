import React, {useState, useEffect} from 'react';

// prodivders
import {useProductsContext, useLoadingProductsContext} from '../../../../contexts/ProductsProvider';
import {useCartContext} from '../../../../contexts/CartProvider';
import {useFavouritesContext} from '../../../../contexts/FavouritesProvider';

// utils
import {useParams} from 'react-router-dom';

// components
import Recommendations from '../recommendations/Recommendations';
import ProductGallery from './productGallery/ProductGallery';

//markdown
import ReactMarkdown from 'react-markdown'

// styling
import './ProductItem.scss';
import {
  CircularProgress,
  Typography,
  Divider,
  Container,
  Grid,
  Box,
} from '@mui/material';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import {FaFacebookF, FaTwitter, FaInstagram, FaYoutube} from 'react-icons/fa';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {LoadingButton} from '@mui/lab';
import {AiOutlineHeart} from 'react-icons/ai';

const Price = ({price, discount}) => {
  return (
    <React.Fragment>
      {discount
        ? <Box display="flex" alignItems="left" pt={1}>
            <Typography
              variant="h5"
              color="secondary"
              align="left"
              fontStyle="italic"
              fontWeight="bold"
              pr={1}
            >
              EUR {(price - discount / 100 * price).toFixed (2)}
            </Typography>

            <Typography
              variant="h6"
              className="strike"
              color="primary"
              align="center"
              fontStyle="italic"
              fontWeight="bold"
            >
              EUR {price}
            </Typography>
          </Box>
        : <Typography
            variant="h5"
            color="secondary"
            align="left"
            fontStyle="italic"
            fontWeight="bold"
            pt={1}
          >
            EUR {price}
          </Typography>}
    </React.Fragment>
  );
};

const ProductItem = ({modalView, param}) => {
  const [product, setProduct] = useState ([]);
  const [loadingActiveProduct, setLoadingActiveProduct] = useState (true);
  const [chosenQuantity, setChosenQuantity] = useState (1);
  
  const loadingProducts = useLoadingProductsContext ();
  const products = useProductsContext ();
  const {getCartProducts, addToCart} = useCartContext();
  const {getFavourites, addToFavourites} = useFavouritesContext();
  const params = useParams ();

  // quantity
  const handleSubstractQuantity = () =>
    setChosenQuantity (chosenQuantity < 2 ? 1 : chosenQuantity - 1);
  const handleAddQuantity = () =>
    setChosenQuantity (chosenQuantity > 8 ? 9 : chosenQuantity + 1);

  useEffect (
    () => {
      setLoadingActiveProduct (true);
      let activeProduct;

      if (modalView) {
        activeProduct = products.filter (
          product => product.title.toLowerCase () === param.toLowerCase ()
        );
      } else {
        let {productTitle} = params;
        productTitle = productTitle.split ('_').join (' ');

        activeProduct = products.filter (
          product => product.title.toLowerCase () === productTitle
        );
      }

      setProduct (activeProduct);
      setLoadingActiveProduct (false);
    },
    [params, products, modalView, param]
  );

  const handleAddToCart = async () => {
    const productDetails = {
      id: product[0].id,
      image: product[0].imageUrl,
      productName: product[0].title,
      price: product[0].price,
      discount: product[0].discount,
    };

    await addToCart (productDetails, chosenQuantity, true);
    await getCartProducts ();
  };

  const handleAddToFavourites = async () => {
    await addToFavourites (product[0].id);
    await getFavourites ();
  };

  let toRenderProduct;

  if (loadingProducts || loadingActiveProduct) {
    toRenderProduct = (
      <div className="d-flex justify-content-center">
        <CircularProgress sx={{mb: 8}} color="secondary" />
      </div>
    );
  } else if (product.length > 0) {
    toRenderProduct = (
      <div className="product-item" style={{marginBottom: '5rem'}}>
        <div className="product-container">
          <Grid container spacing={4}>
            <Grid item lg={6} md={12} className="responsive-grid">
              <ProductGallery product={product} />
            </Grid>
            <Grid item lg={6} md={12}>
              <Typography variant="h4" fontWeight="600">
                {product[0].title}
              </Typography>
              <Price price={product[0].price} discount={product[0].discount} />
              <Divider sx={{my: 3}} />
              <Typography variant="body1" sx={{overflowWrap: "anywhere"}} mb={3}>
                {product[0].short_description}
              </Typography>
              <Typography variant="subtitle1" fontWeight="600">
                Product Guide
              </Typography>
              <div className="d-flex actions-container">
                <div className="quantity guide-element d-flex justify-content-between align-items-center">
                  <button
                    className="button-cart input-button-minus"
                    onClick={handleSubstractQuantity}
                  >
                    -
                  </button>
                  <p className="quantity-counter">{chosenQuantity}</p>
                  <button
                    className="button-cart input-button-plus"
                    onClick={handleAddQuantity}
                  >
                    +
                  </button>
                </div>
                <LoadingButton
                  className="guide-element add-to-cart-button"
                  startIcon={<AddShoppingCartIcon />}
                  loadingPosition="start"
                  variant="contained"
                  color="secondary"
                  onClick={handleAddToCart}
                >
                  Add to cart
                </LoadingButton>
                <div
                  className="add-to-favourite-icon"
                  onClick={handleAddToFavourites}
                >
                  <AiOutlineHeart />
                </div>
              </div>
              <Divider sx={{my: 3}} />
              <div className="d-flex flex-wrap">
                <Typography mr={3} variant="body1" fontWeight="600">
                  {product[0].categories.length === 0
                    ? 'No category'
                    : product[0].categories.length > 1
                        ? 'Categories:'
                        : 'Category'}
                </Typography>
                {product[0].categories.map ((category, index) => (
                  <Typography
                    key={index}
                    className="category-title"
                    mb={2}
                    mr={0.5}
                    variant="body1"
                  >
                    {category.title}
                    {index !== product[0].categories.length - 1 && ','}
                  </Typography>
                ))}
              </div>
              <div className="d-flex flex-wrap">
                <Typography mr={3} variant="body1" fontWeight="600">
                  {product[0].tags.length === 0
                    ? 'No tags'
                    : product[0].tags.length > 1 ? 'Tags:' : 'Tag:'}
                </Typography>
                {product[0].tags.map ((tag, index) => (
                  <Typography
                    key={index}
                    className="tag-name"
                    mb={2}
                    mr={0.5}
                    variant="body1"
                  >
                    {tag.tagName}
                    {index !== product[0].tags.length - 1 && ','}
                  </Typography>
                ))}
              </div>
              <div className="d-flex">
                <Typography mr={3} variant="body1" fontWeight="600">
                  Share:{' '}
                </Typography>
                <div className="d-flex align-items-center gap-1">
                  <FaFacebookF className="share-icon" />
                  <FaInstagram className="share-icon" />
                  <FaTwitter className="share-icon" />
                  <FaYoutube className="share-icon" />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {!modalView &&
          <React.Fragment>
            <div className="description">
              <Divider sx={{mb: 0.2}} />
              <Divider />
              <Typography variant="h6" my={5} fontWeight="600" align="center">
                DESCRIPTION
              </Typography>
              <Typography style = {{textAlign: "center"}} variant="body1" sx={{overflowWrap: "anywhere"}} mb={5}>
                <ReactMarkdown>{product[0].rich_description}</ReactMarkdown>
              </Typography>
              <Divider sx={{mb: 0.2}} />
              <Divider />
            </div>
            <Recommendations products={products} product={product} />
          </React.Fragment>}
      </div>
    );
  } else {
    toRenderProduct = (
      <div className="d-flex justify-content-center">
        <Typography variant="h6" align="center" mb={8}>
          This Product doesn't exist.
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Container maxWidth="xl">
        {toRenderProduct}
      </Container>
    </div>
  );
};

export default ProductItem;
