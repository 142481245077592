import React from 'react';

// styling
import './FloraTitle.scss';
import { Typography } from '@mui/material';

// image
import {ReactComponent as Flora} from '../../assets/SVGs/img_flora.svg';

const FloraTitle = ({title, subtitle}) => {
  return (
    <div className="title-flora">
      <Flora alt="flora" className="flora" />
      <Typography
        className="typo responsive-h3"
        variant="h3"
        fontWeight="600"
        align="center"
      >
        {title}
      </Typography>
      <Typography
        className="typo"
        variant="h6"
        fontStyle="italic"
        align="center"
        pt={1}
      >
        - {subtitle} -
      </Typography>
    </div>
  );
};

export default FloraTitle;
