 import React from 'react'
import axios from 'axios'
import cogoToast from 'cogo-toast';
import Base64Downloader from 'common-base64-downloader-react';
import "./Orders.scss"

import {
    TextField,
    InputAdornment,
    IconButton,
    Typography,
    Divider,
    Slider,
    Button,
    Table,
    TableContainer, 
    TableHead,
    TableRow,
    TableCell,
    TableBody
  } from '@mui/material';

 class Orders extends React.Component{

    
    state = {
        orders : [],
        generatedPDF : ""
    }

    async generateInvoice(id) {
        try {      

            const invoice = await axios.post (
                process.env.REACT_APP_MYHA_URL + '/generateInvoice',
                {orderID : id},
                {
                  headers: {
                    Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
                  },
                }
              );

            if (invoice.status === 200) {
                console.log(invoice.data)
               this.setState({
                   generateInvoice : "data:application/pdf;base64," + invoice.data.pdf
               })
                

            };
          } catch(e) {
            console.log(e);
            if (e.response.status) {
              switch (e.response.status) {
                default:
                  return cogoToast.error("An unexpected error occurred");
              }
            }
          }

    }

    async componentDidMount() {
        try {      

            const orders = await axios.get (
                process.env.REACT_APP_MYHA_URL + '/getMyOrders',
                {
                  headers: {
                    Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
                  },
                }
              );

            if (orders.status === 200) {
                console.log(orders)
                this.setState({
                    orders : orders.data
                })

            };
          } catch(e) {
            console.log(e);
            if (e.response.status) {
              switch (e.response.status) {
                default:
                  return cogoToast.error("An unexpected error occurred");
              }
            }
          }
    }

    render(){

        let allOrders = this.state.orders.map ( (order) => {
            return <TableRow>
                        <TableCell>{order.id}</TableCell>
                        <TableCell>{order.created_at.split("T")[0] + " " + order.created_at.split("T")[1].split(".")[0]}</TableCell>
                        <TableCell>{order.status}</TableCell>
                        <TableCell>{order.totalPrice} EUR </TableCell>
                        {/* <TableCell><Button onClick = {() => this.generateInvoice(order.id)}> Generate Invoice </Button></TableCell> */}
                        <TableCell><Button onClick={()=> window.open(order.invoice, "_blank")}> Download Invoice </Button></TableCell>

                    </TableRow>
        })
        return(

          <div className="orders">
            <h2> My Orders </h2>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Date Created</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Total Price</TableCell>
                    {/* <TableCell>Generate Invoice</TableCell> */}
                    <TableCell>Invoice</TableCell>

                  </TableRow>
            
                </TableHead>
                <TableBody>
                    {allOrders}
                </TableBody>
              </Table>
            </TableContainer>

          </div>
          
            // <div className='table'>
            //     <tr>
            //         <th>Order ID</th>
            //         <th>Date Created</th>
            //         <th>Status</th>
            //         <th>Total Price</th>
            //         <th>Download Invoice</th>

            //     </tr>
            //    {allOrders}
            // </div>
        )
    }
 }


export default Orders;