import React from 'react';

// stylng
import './ProductTextBadge.scss';

const ProductTextBadge = ({text}) => {
  return (
    <div className="product-text-badge">
      {text}
    </div>
  );
};

export default ProductTextBadge;
