export const homePage = '/';
export const signUp = '/sign-up';
export const signIn = '/sign-in';
export const forgotPassword = '/forgot-password';
export const aboutUs = '/about-us';
export const contactUs = '/contact-us';
export const shop = '/shop';
export const cart = '/cart';
export const favourites = '/favourites';
export const checkout = '/checkout';
export const profile = '/profile';

