import React from 'react'
import './Profile.scss'

import Navbar from './Navbar/Navbar'
import Orders from './Orders/Orders'
import NavbarElement from './NavbarElement/NavbarElement'


class Profile extends React.Component{

    state = {
        active: 'My Orders',
        elements : ['My Orders', 'Profile Settings'],
        color : "light-gray"

    }

    handleClick = (element) => {
        console.log(element)
        this.setState({
            active: element
        })
    }

    render(){
        let toRender = <Orders />
        let active = 'My Orders'

        if ( this.state.active === 'My Orders' ){
            toRender = <Orders />
            active = 'My Orders'
        }
        else{
            toRender = <div></div>
            active = 'Profile Settings'

        }

        let navbarElements = this.state.elements.map( (element, index) => {

            let active = false

            if ( this.state.active === element )
                active = true

            console.log(element + " " + active)

            return <NavbarElement onClick = {() => this.handleClick(element)} key={index} active = {active} details = {{element : element, active : this.state.active}} />
        })

        return (

            <div className = "profile">
                <div className="navbar">
                  {navbarElements}

                </div>
                <div className = "orders-table">
                    
                   {toRender}

                </div>

            </div>
    
        )
    }

}


export default Profile;