import React, {useState} from 'react';

// styling
import './ActionButtons.scss';
import {AiOutlineSearch, AiOutlineHeart} from 'react-icons/ai';
import {BsCartPlus} from 'react-icons/bs';

// components
import ProductModal from '../productModal/ProductModal';

// providers
import {useCartContext} from '../../../contexts/CartProvider';
import {useFavouritesContext} from '../../../contexts/FavouritesProvider';

const ActionButtons = ({title, productDetails}) => {
  const [open, setOpen] = useState (false);

  const {getCartProducts, addToCart} = useCartContext ();
  const {getFavourites, addToFavourites} = useFavouritesContext ();

  // product-modal functions
  const onOpenModal = () => setOpen (true);
  const onCloseModal = () => setOpen (false);

  const handleAddToCart = async () => {
    await addToCart (productDetails, 1, true);
    await getCartProducts ();
  };

  const handleAddToFavourites = async () => {
    await addToFavourites (productDetails.id);
    await getFavourites ();
  };

  return (
    <div className="action-buttons">
      <div onClick={handleAddToFavourites} className="action-button">
        <AiOutlineHeart />
      </div>
      <div onClick={handleAddToCart} className="action-button">
        <BsCartPlus />
      </div>
      <div onClick={onOpenModal} className="action-button">
        <AiOutlineSearch />
      </div>
      <ProductModal open={open} onCloseModal={onCloseModal} title={title} />
    </div>
  );
};

export default ActionButtons;
