import React from 'react';

// material-ui
import {Card, CardContent, CardMedia, Typography, Box} from '@mui/material';

// styling
import './CardItem.scss';
import ActionButtons from '../actionButtons/ActionButtons';

// utils
import {useHistory} from 'react-router-dom';

// badges
import DiscountBadge from '../../badges/discountBadge/DiscountBadge';
import ProductTextBadge from '../../badges/productTextBadge/ProductTextBadge';

const Price = ({price, discount}) => {
  return (
    <React.Fragment>
      {discount
        ? <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={1}
          >
            <Typography
              variant="subtitle1"
              color="secondary"
              align="center"
              fontStyle="italic"
              fontWeight="bold"
              pr={1}
            >
              EUR {(price - discount / 100 * price).toFixed (2)}
            </Typography>

            <Typography
              variant="subtitle1"
              className="strike"
              color="secondary"
              align="center"
              fontStyle="italic"
              fontWeight="bold"
            >
              EUR {price}
            </Typography>
          </Box>
        : <Typography
            variant="subtitle1"
            color="secondary"
            align="center"
            fontStyle="italic"
            fontWeight="bold"
            pt={1}
          >
            EUR {price}
          </Typography>}
    </React.Fragment>
  );
};

const CardItem = ({
  title,
  price,
  image,
  discount,
  quantity,
  isFeatured,
  isHot,
  id
}) => {
  const history = useHistory ();

  const handleShowProduct = () => {
    let link = title.toLowerCase ().split (' ');
    link = link.join ('_');

    history.push (`/shop/${link}`);
  };

  let toRenderBadge;

  if (!quantity) {
    toRenderBadge = <ProductTextBadge text={'sold'} />;
  } else if (discount) {
    toRenderBadge = <DiscountBadge discount={discount} />;
  } else if (isFeatured) {
    toRenderBadge = <ProductTextBadge text="featured" />;
  } else if (isHot) {
    toRenderBadge = <ProductTextBadge text="hot" />;
  }

  return (
    <Card
      className="card-item"
      sx={{maxWidth: 290, cursor: 'pointer', boxShadow: 'none'}}
    >
      <div className="card-image">
        <CardMedia
          onClick={handleShowProduct}
          component="img"
          //height="270"
          image={image}
          alt={title}
        />
        {toRenderBadge}
        <ActionButtons 
          productDetails={{
            id: id,
            image: image,
            productName: title,
            price: price,
            discount: discount,
          }}
          title={title} 
        />
      </div>
      <CardContent onClick={handleShowProduct}>
        <Typography
          variant="h6"
          component="h6"
          align="center"
          fontWeight="bold"
          pt={1}
        >
          {title}
        </Typography>
        <Price price={price} discount={discount} />
      </CardContent>
    </Card>
  );
};

export default CardItem;
