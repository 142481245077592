import React, {useState} from 'react';

// styling
import './Subscribe.scss';
import {Container, Button, TextField, Box} from '@mui/material';

// utils
import {emailRegex} from '../../utils/regex';
import axios from 'axios';
import cogoToast from 'cogo-toast';

// component
import FloraTitle from '../floraTitle/FloraTitle';

const Subscribe = () => {
  const [email, setEmail] = useState ('');
  const [emailError, setEmailError] = useState ('');

  const handleInputChange = event => {
    setEmail (event.target.value);
  };

  const isValidated = () => {
    let emailError = '';
    let isFormValidated = true;

    // email validation
    if (!emailRegex.test (email)) {
      isFormValidated = false;
      emailError = 'Email is not valid';
    }

    setEmailError (emailError);
    return isFormValidated;
  };

  const handleSubscribe = async () => {
    if (isValidated ()) {
      try {
        let subscribeReq = await axios.post (
          process.env.REACT_APP_MYHA_URL + '/subscribers',
          {email: email.toLowerCase()}
        );
        if (subscribeReq.status === 200) {
          cogoToast.success ('Thank you for subscribing.');
        }
      } catch (e) {
        console.log (e);
        if (e.response) {
          switch (e.response.status) {
            default:
              return cogoToast.error ('An unexpected error occurred');
          }
        }
      }
    } else {
      return;
    }
  };

  return (
    <section className="subscribe">
      <Container maxWidth="xl">
        <FloraTitle
          title={'Subscribe To Our Newsletter'}
          subtitle={'To Keep Track Of The Latest Designs'}
        />
        <Box
          pt={7}
          className="subscribe-box"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            label="Enter email"
            className="subscribe-field"
            color="secondary"
            onChange={handleInputChange}
            focused
            id="my-em1"
            error={emailError ? true : false}
            helperText={emailError}
          />
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubscribe}
          >
            SUBSCRIBE
          </Button>
        </Box>
      </Container>
    </section>
  );
};

export default Subscribe;
