import React, {useState, useEffect} from 'react';

// styling
import './Recommendations.scss';
import {Typography, Grid} from '@mui/material';

// components
import CardItem from '../../../../components/shopComponents/cardItem/CardItem';

const Recommendations = ({products, product}) => {
  const [recommendations, setRecommendations] = useState ([]);
  useEffect (
    () => {
      let computeRecommendations = [];
      if (products.length > 0 && product.length > 0) {
        computeRecommendations = products.filter (
          pr => pr.id !== product[0].id
        );
        computeRecommendations.sort (() => 0.5 - Math.random ());
        setRecommendations (computeRecommendations.slice (0, 4));
      } else {
        return;
      }
    },
    [products, product]
  );

  let toRenderRecommendations;

  if (recommendations.length === 0) {
    toRenderRecommendations = (
      <div className="d-flex justify-content-center align-items-center">
        <Typography variant="body1" align="center" mt={8}>
          It seems like we don't have any recommendation for you temporary.
        </Typography>
      </div>
    );
  } else {
    toRenderRecommendations = (
      <div className="recommendations">
        <Typography variant="h6" my={5} fontWeight="600" align="center">
          YOU MAY ALSO LIKE THIS
        </Typography>
        <Grid
          container
          rowSpacing={5}
          columnSpacing={{xs: 1, sm: 2, md: 4}}
          pt={4}
        >
          {recommendations.map ((product, index) => (
            <Grid
              className="grid-element"
              key={index}
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <CardItem
                title={product.title}
                price={product.price}
                image={product.imageUrl}
                discount={product.discount}
                quantity={product.quantity}
                isHot={product.isHot}
                isFeatured={product.isFeatured}
                id={product.id}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }

  return (
    <div>
      {toRenderRecommendations}
    </div>
  );
};

export default Recommendations;
