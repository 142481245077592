import React from 'react';

// styling
import {Typography, Container, Box} from '@mui/material';

// components
import SocialBadge from '../badges/socialBadge/SocialBadge';
import { Link } from 'react-router-dom';
import './Footer.scss'

const Footer = () => {
  return (
    <div className="footer" style={{backgroundColor: '#f8f8f8'}}>
      <Container maxWidth="xl">
        <div className="d-flex justify-content-center align-items-center">
          <Box py={5} maxWidth="500px">
            {/* <Typography align="center">1 Recontructiei Street, Bucharest - Romania</Typography> */}
            <Typography align="center" mb={3}>Copyright © 2022 <Typography component="span" color="secondary">Design by Tunder</Typography></Typography>
            <div className="d-flex justify-content-around align-items-center" id='legal-footer'>
                <Link to="/termsAndConditions">Terms & Conditions</Link>
                <Link to="/privacyPolicy">Privacy Policy</Link>
                <Link to="/refundPolicy">Refund Policy</Link>

            </div>
            <div className="d-flex justify-content-around align-items-center">
                <SocialBadge socialIcon="facebook" link="https://www.facebook.com/designedbytunder/" />
                <SocialBadge socialIcon="pinterest" link ="https://ro.pinterest.com/designbytunder/"/>
                <SocialBadge socialIcon="instagram" link="https://www.instagram.com/designbytunder/"/>
                <SocialBadge socialIcon="youtube" link="https://www.youtube.com/c/DesignbyTunder"/>
            </div>
          </Box>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
