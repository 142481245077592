import React, {useState} from 'react';

// styling
import './ContactForm.scss';
import {
  Container,
  Typography,
  Grid,
  Divider,
  TextField,
  Button,
  TextareaAutosize,
} from '@mui/material';

// icons
import {MdLocationOn, MdEmail} from 'react-icons/md';
import {AiTwotonePhone} from 'react-icons/ai';

// utils
import axios from 'axios';
import cogoToast from 'cogo-toast';
import {emailRegex} from '../../../utils/regex';
import SocialBadge from '../../../components/badges/socialBadge/SocialBadge';

const GeoPosition = () => {
  const [contactObj, setContactObj] = useState ({
    name: null,
    email: null,
    subject: '',
    message: '',
  });

  const handleInputChange = event => {
    setContactObj ({
      ...contactObj,
      [event.target.name]: event.target.value,
    });
  };

  const isEmpty = () => {
    if (!contactObj.name || !contactObj.email) {
      return false;
    } else {
      return true;
    }
  };

  const handleSendMessage = async () => {
    if (!emailRegex.test (contactObj.email)) {
      return cogoToast.error ('Invalid email');
    } else {
      try {
        let sendMessageReq = await axios.post (
          process.env.REACT_APP_MYHA_URL + '/messages',
          contactObj
        );
        if (sendMessageReq.status === 200) {
          cogoToast.success ('Thank you for contacting us.');
        }
      } catch (e) {
        console.log (e);
        if (e.response) {
          switch (e.response.status) {
            default:
              return cogoToast.error ('An unexpected error occurred');
          }
        }
      }
    }
  };

  return (
    <section className="contact-form-section">
      <Container maxWidth="xl">
        <Grid container spacing={6}>
          {/* <Grid item md={6} sm={12}>
            <Divider sx={{mb: 0.2}} />
            <Divider />
            <div className="d-flex flex-column">
              <Typography variant="h6" fontWeight="600" my={2}>
                CONTACT US
              </Typography>
              <Typography variant="body1" mt={2} mb={5}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                {' '}
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </Typography>
              <div className="d-flex contact-detail">
                <div className="icon-container">
                  <MdLocationOn />
                </div>
                <div className="content-container">
                  <Typography variant="h6" fontWeight="600" mb={1}>
                    ADDRESS
                  </Typography>
                  <Typography variant="body1">
                    6th Forrest Ray, Manhattan NYC 10001, USA
                  </Typography>
                </div>
              </div>
              <div className="d-flex contact-detail">
                <div className="icon-container">
                  <AiTwotonePhone />
                </div>
                <div className="content-container">
                  <Typography variant="h6" fontWeight="600" mb={1}>
                    PHONE
                  </Typography>
                  <Typography variant="body1">
                    Mobile 1: (+01)-212-33-6789
                  </Typography>
                  <Typography variant="body1">
                    Mobile 2: (+01)-212-66-8888
                  </Typography>
                  <Typography variant="body1">
                    Hotline: 1900 888 888
                  </Typography>
                </div>
              </div>
              <div className="d-flex contact-detail">
                <div className="icon-container">
                  <MdEmail />
                </div>
                <div className="content-container">
                  <Typography variant="h6" fontWeight="600" mb={1}>
                    EMAIL
                  </Typography>
                  <Typography variant="body1">
                    contact@myha.com
                  </Typography>
                  <Typography variant="body1">
                    support@myha.com
                  </Typography>
                </div>
              </div>
              <div className="badges-container d-flex align-items-center">
                <SocialBadge dimension="40px" socialIcon="facebook" />
                <SocialBadge dimension="40px" socialIcon="twitter" />
                <SocialBadge dimension="40px" socialIcon="instagram" />
                <SocialBadge dimension="40px" socialIcon="youtube" />
              </div>
            </div>
          </Grid> */}
          <Grid item md={6} sm={12}>
            <Divider sx={{mb: 0.2}} />
            <Divider />
            <div className="d-flex flex-column">
              <Typography variant="h6" fontWeight="600" my={2}>
                CONTACT US
              </Typography>
              <Typography variant="body1" mt={2} mb={5}>
                If you have any questions, please fell free to get in touch with us. We will reply to you as soon as possible. Thank you!
              </Typography>
              <div className="d-flex justify-content-between">
                <TextField
                  onChange={handleInputChange}
                  placeholder={'Your name'}
                  name="name"
                  autoComplete="on"
                  color="secondary"
                  size="small"
                  type="text"
                  id="my-nm"
                  className="textfield-width-control"
                  error={contactObj.name === ''}
                  helperText={
                    contactObj.name === '' ? 'This field is required!' : ' '
                  }
                />
                <TextField
                  onChange={handleInputChange}
                  placeholder={'Your email'}
                  name="email"
                  autoComplete="on"
                  color="secondary"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  size="small"
                  type="text"
                  id="my-em2"
                  className="textfield-width-control"
                  error={contactObj.email === ''}
                  helperText={
                    contactObj.email === '' ? 'This field is required!' : ' '
                  }
                />
              </div>
              <TextField
                onChange={handleInputChange}
                placeholder={'Subject'}
                name="subject"
                autoComplete="on"
                color="secondary"
                size="small"
                type="text"
                id="my-sb"
                sx={{mt: 2}}
              />
              <TextareaAutosize
                aria-label="message"
                minRows={6}
                placeholder="Message"
                className="text-area"
                name="message"
                onChange={handleInputChange}
              />
            </div>
            <Button
              onClick={handleSendMessage}
              variant="contained"
              disabled={!isEmpty ()}
              color="secondary"
              sx={{mt: 3}}
            >
              Send message
            </Button>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default GeoPosition;
