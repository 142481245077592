import React from 'react';

// styling
import './TestimonialsSection.scss';
import Carousel from 'react-material-ui-carousel';
import {
  Container,
  Paper,
  Rating,
  Typography,
  Avatar,
  Divider,
} from '@mui/material';

// images
import TestimonialAvatar from '../../../assets/PNGs/testimonial-avatar.jpg';
import Company from '../../../assets/PNGs/client-retro.png';

const TestimonialsSection = () => {
  const upperItems = [
    {
      title: 'BEAUTIFUL ACCESORIES STORE',
      description: 'Thank you for all the amazing products you deliver each month.. I’ve been telling everyone about your greatest Hair Accessories Store !',
      name: 'MARY LOU',
      job: 'Developer',
    },
    {
      title: 'BEAUTIFUL ACCESORIES STORE',
      description: 'Thank you for all the amazing products you deliver each month.. I’ve been telling everyone about your greatest Hair Accessories Store !',
      name: 'MARY LOU',
      job: 'Developer',
    },
  ];

  const lowerItems = [Company, Company];

  return (
    <section className="testimonials-section">
      <Container className="testimonials-container" maxWidth="xl">
        <div className="upper-carousel-container">
          <Carousel
            className="upper-carousel"
            fullHeightHover={true}
            indicators={false}
            interval={10000}
            animation="slide"
          >
            {upperItems.map ((item, index) => (
              <React.Fragment key={index}>
                <Paper />
                <div className="carousel-box">
                  <div className="carousel-box-content">
                    <Avatar
                      alt="testimonial-avatar"
                      src={TestimonialAvatar}
                      sx={{width: 120, height: 120, margin: 'auto', mb: 3}}
                    />
                    <Typography
                      className="white-testimonial-text"
                      variant="h4"
                      align="center"
                      fontWeight="600"
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      pt={3}
                      mb={3}
                      className="light-testimonial-text"
                      align="center"
                      fontStyle="italic"
                      maxWidth="700px"
                    >
                      {item.description}
                    </Typography>
                    <div className="rating d-flex flex-column align-items-center">
                      <Rating
                        name="read-only"
                        value={5}
                        sx={{mb: 1}}
                        readOnly
                      />
                      <div className="user-details d-flex justify-content-center">
                        <Typography
                          align="center"
                          className="white-testimonial-text"
                          mr={1}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          align="center"
                          className="white-testimonial-text"
                          mr={1}
                        >
                          /
                        </Typography>
                        <Typography
                          align="center"
                          className="light-testimonial-text"
                          mr={1}
                        >
                          {item.job}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </Carousel>
        </div>
        <Divider className="divider" sx={{position: 'absolute', left: 0, width: '100%', backgroundColor: '#C0C0C0'}} />
        <div className="lower-carousel-container">
        <Carousel
            className="lower-carousel"
            fullHeightHover={true}
            indicators={false}
            interval={10000}
            animation="slide"
          >
            {lowerItems.map ((item, index) => (
              <React.Fragment key={index}>
                <Paper />
                <div className="carousel-box">
                  <div className="carousel-box-content">
                    <img src={item} alt="company" className="company-logo" />
                  </div>
                </div>
              </React.Fragment>
            ))}
          </Carousel>
        </div>
      </Container>
    </section>
  );
};

export default TestimonialsSection;
