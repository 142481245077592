import React, { useState } from "react";
import "./ProductGallery.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InnerImageZoom from "react-inner-image-zoom";

export default function SyncSlider({ product }) {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  console.log(product);
  return (
    <div className="product-gallery">
      <Slider
        infinite={false}
        slidesToScroll={1}
        slidesToShow={1}
        asNavFor={nav2}
        ref={(slider1) => setNav1(slider1)}
      >
        {product[0].gallery.length > 3 ?
          product[0].gallery.map((prod) => (
            <div key={prod.id}>
              <InnerImageZoom
                className="product-image"
                zoomPreload={true}
                zoomType="hover"
                src={prod.imageUrl}
                alt="product"
              />
            </div>
          )) : (
            <InnerImageZoom
            className="product-image"
            zoomPreload={true}
            zoomType="hover"
            src={product[0].imageUrl}
            alt="product"
          />
          )}
      </Slider>
      <Slider
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={4}
        slidesToScroll={1}
        centerMode={false}
        focusOnSelect={true}
        vertical={false}
        arrows={true}
        swipeToSlide={true}
        className="product-thumbnails1"
        responsive={[
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
            },
          },
        ]}
      >
        {product[0].gallery.length > 3 &&
          product[0].gallery.map((prod) => (
            <div className="product-image-container" key={prod.id}>
              <img
                className="product-image"
                src={prod.imageUrl}
                alt="product-thumbnail"
              />
            </div>
          ))}
      </Slider>
    </div>
  );
}

