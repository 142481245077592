import React from 'react';

// stylng
import {Zoom, Box, useScrollTrigger} from '@mui/material';

const ScrollToTop = props => {
  const {children} = props;

  const trigger = useScrollTrigger ({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{position: 'fixed', bottom: 16, right: 16}}
      >
        {children}
      </Box>
    </Zoom>
  );
};

export default ScrollToTop;
