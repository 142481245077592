import React, {useState, useContext, useEffect} from 'react';
// utils
import axios from 'axios';
import cogoToast from 'cogo-toast';

const FavouritesContext = React.createContext ();

export const useFavouritesContext = () => useContext (FavouritesContext);

const FavouritesProvider = ({children}) => {
  const [favourites, setFavourites] = useState ([]);
  const [loadingFavourites, setLoadingFavourites] = useState (false);

  const getFavourites = async () => {
    // if user is logged in get favourites from db
    // else from local storage
    setLoadingFavourites (true);
    if (localStorage.getItem ('jwt')) {
      try {
        const getFavouritesReq = await axios.get (
          process.env.REACT_APP_MYHA_URL + '/getFavourites',
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
            },
          }
        );

        if (getFavouritesReq.status === 200) {
          setFavourites (getFavouritesReq.data);
        }
      } catch (e) {
        console.log (e);
        if (e.response) {
          switch (e.response.status) {
            default:
              return cogoToast.error ('An unexpected error occurred');
          }
        }
      } finally {
        setLoadingFavourites (false);
      }
    } else {
      const lsFavourites = JSON.parse (localStorage.getItem ('favourites'));

      if (lsFavourites) {
        setFavourites (lsFavourites);
      } else {
        setFavourites ([]);
      }

      setLoadingFavourites (false);
    }
  };

  const addToFavourites = async productId => {
    // if user is logged in, add to db favourites, else add in localStorage
    if (localStorage.getItem ('jwt')) {
      try {
        const obj = {
          productId: productId,
        };

        const addToFavouritesReq = await axios.post (
          process.env.REACT_APP_MYHA_URL + '/addToFavourites',
          obj,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
            },
          }
        );

        if (addToFavouritesReq.status === 200) {
          if (addToFavouritesReq.data) {
            return cogoToast.success (
              'Product added succesfully to favourites'
            );
          } else {
            return cogoToast.error ('Product already in favourites');
          }
        }
      } catch (e) {
        console.log (e);
        if (e.response) {
          switch (e.response.status) {
            case 401:
              return cogoToast.error (
                'You have to be logged in to add products to favourites'
              );
            default:
              return cogoToast.error ('An unexpected error occurred');
          }
        }
      }
    } else {
      let lsFavourites = JSON.parse (localStorage.getItem ('favourites'));

      if (!lsFavourites) {
        lsFavourites = [];
      }

      if (lsFavourites.find (prod => prod.id === productId)) {
        return cogoToast.error ('Product already in favourites');
      } else {
        try {
          const productReq = await axios.get (
            process.env.REACT_APP_MYHA_URL + `/products/${productId}`
          );
          if (productReq.status === 200) {
            const updatedFavourites = [...lsFavourites, productReq.data];
            localStorage.setItem ('favourites', JSON.stringify (updatedFavourites));
            return cogoToast.success ('Product added succesfully to favourites');
          }
        } catch (e) {
          console.log (e);
        }
      }
    }
  };

  const removeFromFavourites = async productId => {
    // if user is logged in remove favourite from db
    // else remove from local storage
    if (localStorage.getItem ('jwt')) {
      try {
        const obj = {
          productId: productId,
        };

        const removeFromFavouritesReq = await axios.post (
          process.env.REACT_APP_MYHA_URL + '/removeFromFavourites',
          obj,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
            },
          }
        );

        if (removeFromFavouritesReq.status === 200) {
          return cogoToast.success (
            'Product removed succesfully from favourites'
          );
        }
      } catch (e) {
        console.log (e);
        if (e.response) {
          switch (e.response.status) {
            case 403:
              return cogoToast.error (
                'You have to be logged in to remove products from favourites'
              );
            default:
              return cogoToast.error ('An unexpected error occurred');
          }
        }
      }
    } else {
      const lsFavourites = JSON.parse (localStorage.getItem ('favourites'));

      const updatedFavourites = lsFavourites.filter(product => product.id !== productId);

      localStorage.setItem ('favourites', JSON.stringify (updatedFavourites));
      return cogoToast.success ('Product removed succesfully from favourites');
    }
  };

  useEffect (() => {
    getFavourites ();
  }, []);

  return (
    <FavouritesContext.Provider
      value={{
        favourites,
        getFavourites,
        loadingFavourites,
        addToFavourites,
        removeFromFavourites,
      }}
    >
      {children}
    </FavouritesContext.Provider>
  );
};

export default FavouritesProvider;
