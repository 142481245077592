import React from 'react'
import {Button} from '@mui/material'
import './NavbarElement.scss'

class NavbarElement extends React.Component{


    render(){
        
        console.log(this.props.active)

        return (
            <Button  onClick = {this.props.onClick} color = {this.props.active === true?"success":"secondary"} className = "navbar-element">
                <p> {this.props.details.element} </p>
            </Button >
        )
    }
}

export default NavbarElement;