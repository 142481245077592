import React from 'react';

// stylng
import './DiscountBadge.scss';

const DiscountBadge = ({discount}) => {
    return (
        <div className="discount-badge">
            {discount}%
        </div>
    )
}

export default DiscountBadge;