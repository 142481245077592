import React, {useState} from 'react';

// utils
import {useHistory} from 'react-router-dom';
import {forgotPassword, homePage} from '../../../utils/routes';
import {login} from '../../../utils/isLogin';
import axios from 'axios';
import cogoToast from 'cogo-toast';

// styling
import './SignIn.scss';
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  Link,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// components
import Subscribe from '../../../components/subscribe/Subscribe';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

// providers
import {useCartContext} from '../../../contexts/CartProvider';
import {useFavouritesContext} from '../../../contexts/FavouritesProvider';
import {useSetUserContext} from '../../../contexts/UserProvider';

const SignIn = () => {
  const [signInObj, setSignInObj] = useState ({
    email: '',
    password: '',
    showPassword: false,
  });

  const {getCartProducts} = useCartContext();
  const {getFavourites} = useFavouritesContext();
  const setUserData = useSetUserContext ();
  const history = useHistory ();

  const handleInputChange = event => {
    setSignInObj ({
      ...signInObj,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setSignInObj ({
      ...signInObj,
      showPassword: !signInObj.showPassword,
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault ();
  };

  // form validation
  const isValidated = () => {
    let isFormValidated = true;

    if (signInObj.email.length < 3 || signInObj.password.length < 8) {
      isFormValidated = false;
    }

    return isFormValidated;
  };

  // signIn possible just if button is enabled ( form is validated )
  const handleSignIn = async () => {
    try {
      const signIn = await axios.post (
        process.env.REACT_APP_MYHA_URL + '/auth/local',
        {
          identifier: signInObj.email,
          password: signInObj.password,
        }
      );

      if (signIn.status === 200) {
        // auth
        cogoToast.success ('Successfully authenticated');
        login (signIn.data.jwt);
        setUserData (signIn.data.user);

        // cart && fav
        localStorage.removeItem('cart');
        localStorage.removeItem('favourites');
        getCartProducts();
        getFavourites();

        history.push (homePage);
      }
    } catch (e) {
      console.log (e);
      if (e.response.status) {
        switch (e.response.status) {
          case 400:
            return cogoToast.error ('Wrong Email or Password');
          default:
            return cogoToast.error ('An unexpected error occurred');
        }
      }
    }
  };

  return (
    <div className="sign-in">
      <Breadcrumbs />
      <Container maxWidth="xl" className="sign-in-container">
        <Typography pb={3} variant="h4" fontWeight="bold">LOGIN</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 3,
            border: `1px solid #ababab`,
          }}
          component="form"
        >
          <InputLabel sx={{fontWeight: 'bold'}} htmlFor="my-em">
            Email address <sup style={{color: 'red'}}>&#42;</sup>
          </InputLabel>
          <FormControl>
            <TextField
              onChange={handleInputChange}
              name="email"
              autoComplete="on"
              color="secondary"
              size="small"
              type="email"
              id="my-em4"
            />
          </FormControl>
          <InputLabel htmlFor="my-pass" sx={{mt: 3, fontWeight: 'bold'}}>
            Password <sup style={{color: 'red'}}>&#42;</sup>
          </InputLabel>
          <FormControl>
            <TextField
              onChange={handleInputChange}
              name="password"
              size="small"
              id="my-pass"
              type={signInObj.showPassword ? 'text' : 'password'}
              color="secondary"
              autoComplete="on"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {signInObj.showPassword
                        ? <VisibilityOff />
                        : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <div className="d-flex">
            <Button
              onClick={handleSignIn}
              variant="contained"
              disabled={!isValidated ()}
              color="secondary"
              sx={{width: '100px', mt: 3}}
            >
              LOG IN
            </Button>

          </div>
          <Link
            variant="body1"
            onClick={() => history.push (forgotPassword)}
            mt={3}
            sx={{cursor: 'pointer'}}
          >
            Lost your password?
          </Link>
        </Box>
      </Container>
      <Subscribe />
    </div>
  );
};

export default SignIn;
