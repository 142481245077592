import React, {useState} from 'react';

// utils
import {useHistory} from 'react-router-dom';
import {cart, homePage, signIn} from '../../../utils/routes';
import CartProvider from '../../../contexts/CartProvider';
import {login} from '../../../utils/isLogin';

import {emailRegex, passwordRegex} from '../../../utils/regex';
import axios from 'axios';
import cogoToast from 'cogo-toast';

// styling
import './SignUp.scss';
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  Link,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// components
import Subscribe from '../../../components/subscribe/Subscribe';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';


const syncCartWithServer = async () => {

  const lsCart = JSON.parse(localStorage.getItem('cart'));

  const addToCartReq = await axios.post (
    process.env.REACT_APP_MYHA_URL + '/createCart',
    lsCart,
    {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem ('jwt'),
      },
    }
  );

  if (addToCartReq.status === 200) {
    // return cogoToast.success ('Products added succesfully to cart');
  }


}

const SignUp = () => {
  const [signUpObj, setSignUpObj] = useState ({
    email: '',
    password: '',
    lastName: '',
    firstName: '',
    showPassword: false,
  });

  const [errors, setErrors] = useState ({
    emailError: '',
    passwordError: '',
    lastNameError: '',
    firstNameError: '',
  });

  const history = useHistory ();

  const handleInputChange = event => {
    setSignUpObj ({
      ...signUpObj,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setSignUpObj ({
      ...signUpObj,
      showPassword: !signUpObj.showPassword,
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault ();
  };

  // sign up form validation
  const isValidated = () => {
    const {email, password, lastName, firstName} = {...signUpObj};
    let errors = {
      emailError: '',
      passwordError: '',
      lastNameError: '',
      firstNameError: ''
    };
    let isFormValidated = true;

    // email validation
    if (!emailRegex.test (email)) {
      isFormValidated = false;
      errors.emailError = 'Email is not valid';
    }

    // password validation
    if (!passwordRegex.test (password)) {
      isFormValidated = false;
      errors.passwordError = 'Password should contain at least one digit, one symbol, one lower case, one upper case, and 8 characters.';
    }

    // last name vaildation
    if (lastName.length < 3) {
      isFormValidated = false;
      errors.lastNameError = 'Last Name should have at least 3 characters.';
    }

    // first name validation
    if (firstName.length < 3) {
      isFormValidated = false;
      errors.firstNameError = 'First Name should have at least 3 characters.';
    }

    setErrors(errors);
    return isFormValidated;
  };

  const handleRegister = async () => {
    if (isValidated ()) {
      try {
        const capitalizeFn = signUpObj.firstName.charAt(0).toUpperCase() + signUpObj.firstName.slice(1);
        const capitalizeLn = signUpObj.lastName.charAt(0).toUpperCase() + signUpObj.lastName.slice(1);
        const username = signUpObj.email.toLowerCase();

        const signUp = await axios.post(process.env.REACT_APP_MYHA_URL + '/auth/local/register', {
          lastName: signUpObj.lastName,
          firstName: signUpObj.firstName,
          username: username,
          email: signUpObj.email,
          password: signUpObj.password,
        });
  
        if (signUp.status === 200) {

          console.log(signUp.data)
          login (signUp.data.jwt);

          // localStorage.setItem ('jwt', signUp.jwt);
          cogoToast.success('Account created successfully!');

          if (localStorage.getItem("cart") !== null){
            await syncCartWithServer(localStorage.getItem("cart"))
            history.push(cart);
            window.location.reload();

          }
          else{
            history.push(homePage);
            window.location.reload();

          }
          // syncCartWithServer
        };
      } catch(e) {
        console.log(e);
        if (e.response) {
          switch (e.response.status) {
            default:
              return cogoToast.error("An account with this email already exists");
          }
        }
      }
    } else {
      return;
    }
  };

  return (
    <div className="sign-up">
      <Breadcrumbs />
      <Container maxWidth="xl" className="sign-up-container">
        <Typography pb={3} variant="h4" fontWeight="bold">REGISTER</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 3,
            border: `1px solid #ababab`,
          }}
          component="form"
        >
          <InputLabel sx={{fontWeight: 'bold'}} htmlFor="my-ln">
            Last name <sup style={{color: 'red'}}>&#42;</sup>
          </InputLabel>
          <FormControl>
            <TextField
              onChange={handleInputChange}
              name="lastName"
              autoComplete="on"
              color="secondary"
              size="small"
              type="text"
              id="my-ln"
              error={errors.lastNameError ? true : false}
              helperText={errors.lastNameError}
            />
          </FormControl>
          <InputLabel sx={{fontWeight: 'bold', mt: 3}} htmlFor="my-fn">
            First name <sup style={{color: 'red'}}>&#42;</sup>
          </InputLabel>
          <FormControl>
            <TextField
              onChange={handleInputChange}
              name="firstName"
              autoComplete="on"
              color="secondary"
              size="small"
              type="email"
              id="my-fn"
              error={errors.firstNameError ? true : false}
              helperText={errors.firstNameError}
            />
          </FormControl>
          <InputLabel sx={{fontWeight: 'bold', mt: 3}} htmlFor="my-em">
            Email address <sup style={{color: 'red'}}>&#42;</sup>
          </InputLabel>
          <FormControl>
            <TextField
              onChange={handleInputChange}
              name="email"
              autoComplete="on"
              color="secondary"
              size="small"
              type="email"
              id="my-em5"
              error={errors.emailError ? true : false}
              helperText={errors.emailError}
            />
          </FormControl>
          <InputLabel htmlFor="my-pass" sx={{mt: 3, fontWeight: 'bold'}}>
            Password <sup style={{color: 'red'}}>&#42;</sup>
          </InputLabel>
          <FormControl>
            <TextField
              onChange={handleInputChange}
              name="password"
              size="small"
              id="my-pass"
              type={signUpObj.showPassword ? 'text' : 'password'}
              color="secondary"
              autoComplete="on"
              error={errors.passwordError ? true : false}
              helperText={errors.passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {signUpObj.showPassword
                        ? <VisibilityOff />
                        : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <div className="d-flex">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRegister}
              sx={{width: '100px', mt: 3}}
            >
              REGISTER
            </Button>

          </div>
          <Link
            variant="body1"
            onClick={() => history.push (signIn)}
            mt={3}
            sx={{cursor: 'pointer'}}
          >
            Already have an account?
          </Link>
        </Box>
      </Container>
      <Subscribe />
    </div>
  );
};

export default SignUp;
